/* global grecaptcha */

import { ajaxSend } from "./fetch";
import { loadLib } from "./loadlib";

var _recaptchaRendered = false;
var _recaptchaReady = false;
var _publicKey = null;

/**
 * подключает рекапчу, но не отображает ее
 * @param {string} publicKey 
 */
function initRecaptcha(publicKey) {
    _publicKey = publicKey;

    window.recaptchaCallback = function() {
        _recaptchaReady = true;
        document.dispatchEvent(new Event('tu.recaptcha.ready'));
    };

    return loadLib({
        jsLink: "https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit&hl=ru",
        conditionObject: window.grecaptcha
    });
}

function recaptchaReady(){
    if (_recaptchaReady) return Promise.resolve();

    return new Promise(resolve => {
        document.addEventListener('tu.recaptcha.ready', resolve);
    });
}

/**
 * дожидается загрузки рекапчи и отображает ее в указанном контейнере
 * 
 * если капча уже показана, то сразу вернется promise с { result: false, alreadyRendered: true }
 * 
 * иначе возвращается promise с объектом { result: boolean, token: string }, 
 * в котором then отрабатывает не после загрузки капчи, а после прохождения
 * 
 * запускать только после initRecaptcha
 * @param {HTMLElement | string} container
 * @returns {Promise<Object>}
 */
function renderRecaptcha(container){
    if (_recaptchaRendered) return Promise.resolve({ result: false, alreadyRendered: true });
    if (!container && !document.getElementById(container)) return Promise.reject({ result: false, noContainer: true });

    return recaptchaReady().then(() => {
        return new Promise((resolve, reject) => {
            grecaptcha.render(container, {
                sitekey: _publicKey,
                callback: token => {
                    ajaxSend({ url: "/signup/verifyCaptcha", data: { token: token } }).then(data => {
                        if (data.result) {
                            _recaptchaRendered = true;
                            data.token = token;
                            resolve(data);
                        }
                        else {
                            reject(data);
                        }
                    });
                }
            });

            _recaptchaRendered = true;
        });
    });
}

export { initRecaptcha, renderRecaptcha };