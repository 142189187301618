﻿/* global tumarket */

import * as $ from "jquery";

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/modal";
import notifications from "./notifications.js";
import tumGlobal from "./global.js";
import { showDialog } from "./global/dialog";
import { ajaxSend } from "./global/fetch";
import { getUrlParameter } from "./global/url";
import { getCookie, setCookie } from "./global/cookie";
import { getPrecompiledTemplate, loadPrecompiledTemplates, loadTemplates } from "./global/templates";
import { addEventDelegate } from "./global/dom";

import "../css/modules/chat/chat.css";
import { initRecaptcha, renderRecaptcha } from "./global/recaptcha.js";

var chat = {
	options: {
		container: "",
		startChatButton: "",
		hubName: "signalRHub",
		username: "",
		isAuthenticated: false,
		role: "client", //client, agent, buyer, seller, employee
		firmID: 0,
		productID: 0,
		orderID: 0,
		url: {
			GetRoom: "/chat/getroom",
			GetRooms: "/chat/getrooms",
			SendMessage: "/chat/sendmessage",
			DeleteMessage: "/chat/deletemessage",
			GetRoomAvailableUsers: "/chat/getroomavailableusers",
			AddUserToRoom: "/chat/addusertoroom",
			RemoveUserFromRoom: "/chat/removeuserfromroom",
			SendVerificationCode: "/chat/sendverificationcode",
			VerifyUser: "/chat/verifyuser",
			CheckUnauthData: "/chat/checkunauthdata",
			MarkAsRead: "/chat/markasread",
		},
		canAccessChat: true,
		chatDisabledMessage: "",
		isCabinet: false,
		pageSize: 20,
		page: 1,
		messagesPageSize: 15,
		messagesPage: 1,
		messagesPaginationLastID: 0,
		scrollDownOnLoad: false,
        callback: null,
		pageTypeCode: "",

		//для заказов
		isSellerChat: false,
		isAdminChat: false
    },
    currentText: {},
	anonName: "",
	anonEmail: "",
	anonPhone: "",
	userFilter: 0,
	productFilter: 0,
	firmFilter: 0,
	pageSizeFilter: 0,
	orderFilter: "",
	dateFilter: "",
	unreadFilter: false,
	canEditUsers: false,
	timer: 0,
	interval: null,
	recaptchaKey: "",
	users: [],
	newMessageAttachments: [],
	total: 0,
	totalMessages: 0,
	messagesLoaded: 0,
	registrationSuccess: false,
	init: function (options) {
		this.options = $.extend(this.options, options);

		if (!this.options.canAccessChat) {
			$(this.options.container).html(this.options.chatDisabledMessage);
			return;
		}

		this.initCallbacks();

		$(document).on('change', '.tum-result-container select', () => {
			this.pageSizeFilter = $('.tum-result-container select').val() || 10;
			this.options.page = 1;

			this.getRooms();
		});

		notifications.registerCallback('incrementMessageCount', data => this.incrementMessageCount(data));
		notifications.registerCallback('messageReceived', data => this.messageReceived(data));
		notifications.registerCallback('updateReadBy', data => this.updateReadBy(data));

		this.hub = notifications.hub;
		window.addEventListener('signalRReady', () => this.hub = notifications.hub);
	},
	_loadChat: function(callback){
		let self = this;

		if (!this.options.isAuthenticated) {
			$(this.options.container).html(this.renderUnauthForm());
		}

		if ($(this.options.container).hasClass("tum-chat-running") || $(this.options.container).find(".tum-chat-unauthBlock").length) {
			if (callback) callback();
			return;
		}

		if ('hub' in chat){
			this.initChat(callback);
		}
		else {
			$(document).on("tumChatIsReady", function() {
				self.initChat(callback);
			});
		}
		
		//инициализация хаба и функций signalr делается в notifications.js
		//this.initHub();
	},
	loadChat: function(callback){
		if (!this.options.isAuthenticated) {
			initRecaptcha(tumarket.recaptchaSiteKey);
			renderRecaptcha('chat-recaptcha-container').then(data => {
				chat.recaptchaKey = data.token;
			}).catch(data => {
				if (data.noContainer) throw new Error("recaptcha container missing");
			});
		}

		loadTemplates(['/template/chat-unauthorized-modal']).then(() => {
			return loadPrecompiledTemplates([
				'chat',
				'chat-room-list',
				'chat-message',
				'chat-unauthorized',
				'chat-read-by-user',
				'chat-read-by-users-all',
				'chat-add-user',
				'chat-message-attachments'
			]);
		}).then(() => {
			this._loadChat(callback)
		});
	},
	initCallbacks: function () {
		let self = this;
		if (this.options.startChatButton) {
			$(document).on("click", this.options.startChatButton, () => this.loadChat());

			var isUrlShowChat = getUrlParameter("showChat") == "1";

			if (getCookie("tum-chat-openChatOnLoad") || isUrlShowChat) {
				this.focusChat();
				setCookie("tum-chat-openChatOnLoad", "0", -1);
			}
		} else {
			this.loadChat();
		}

		addEventDelegate(document, 'click', ".tum-chat-roomListItem", element => {
			var id = $(element).attr("data-id");
			this.getRoom({ roomID: id });
		});

		addEventDelegate(document, 'click', ".tum-chat-sendMessage", element => {
			var text = $(".tum-chat-newMessageText").val();
			var roomID = $(element).attr("data-roomid");

			//если roomID == -1, то сейчас юзер видит просто заглушку и надо создать реальную комнату перед отправкой
			if (Number(roomID) == -1) {
				this.getRoom({
					callback: (newRoomID) => this.sendMessage(text, newRoomID)
				});
			} else {
				this.sendMessage(text, roomID);
			}
		});

		addEventDelegate(document, 'click', ".tum-chat-showAddUserDialog", element => {
			var roomID = $(element).attr("data-roomid");
			this.showAddUserDialog(roomID);
		});

		addEventDelegate(document, 'click', ".tum-chat-addUser", element => {
			var roomID = $(element).attr("data-roomid");
			var username = $(".tum-chat-addUserSelect").val();

            var userType = $(".tum-chat-addUserSelect option[value='" + username + "']").attr("data-type");

			this.addUserToRoom(username, roomID, userType);
		});

		addEventDelegate(document, 'click', ".tum-chat-removeUser", element => {
			var roomID = $(element).attr("data-roomid");
			var username = $(element).closest("li").attr("data-username");

			this.removeUserFromRoom(username, roomID);
		});

		$(document).on("tempUploadComplete-asUploadHTML5", function () {
			import("./as/as.sys").then(m => {
				let as = m.default;
				import("./as/controls/as.uploadHTML5").then(() => {
					as.uploadHTML5.options.filesLeft.forEach(function (e) {
						if (self.newMessageAttachments.indexOf(e.path) == -1) {
							self.newMessageAttachments.push(e.path);
						}
						self.updateNewMessageAttachments();
					});
				})
			});
		});

		addEventDelegate(document, 'click', ".tum-chat-removeNewMessageAttachment", element => {
			var link = $(element).closest("div").find(".tum-chat-newMessageAttachment");
			var url = link.attr("href");
			this.newMessageAttachments = this.newMessageAttachments.filter(e => e != url);
			$(element).closest("div").remove();
		});

		addEventDelegate(document, 'click', ".tum-chat-unauthJoinChat", () => {
			$(".tum-chat-unauthError").html("");
			var name = $(".tum-chat-unauthName").val();
			var emailPhone = $(".tum-chat-unauthEmailPhone").val();
			if (!name || !emailPhone || (!self.recaptchaKey && !tumarket.isAuthenticated)) {
				$(".tum-chat-unauthError").html("Заполните все поля и пройдите капчу");
				return;
			}
			this.getRoomAnonymous(name, emailPhone);
		});

		addEventDelegate(document, 'click', ".tum-chat-verify", () => {
			this.verifyUser();
		});

		addEventDelegate(document, 'click', ".tum-chat-sendCode", () => {
			this.sendVerificationCode();
		});

		addEventDelegate(document, 'click', ".tum-chat-showRooms", () => {
			this.getRooms();
		});

		addEventDelegate(document, "keypress", ".tum-chat-newMessageText", (element, e) => {
			var keyCode = e.keyCode || e.which;
			if (keyCode == 13 && !e.shiftKey) {
				e.preventDefault();
				$(".tum-chat-sendMessage")[0]?.click();
				return false;
			}
		});

		addEventDelegate(document, 'click', ".tum-chat-yesItsMe", () => {
			$(".tum-chat-passRestore").attr("href", "/signup?passRestore=" + (this.anonEmail || this.anonPhone))
			this.unauthModalGoTo("login");
		});

		addEventDelegate(document, 'click', ".tum-chat-noItsNotMe", () => {
			this.unauthModalGoTo("enterRealData");
		});

		addEventDelegate(document, 'click', ".tum-chat-login", () => {
			var params = {
				login: $(".tum-chat-unauthEmailPhone").val(), passwordIn: $(".tum-chat-userPassword").val(), redirectUrl: location.href, rememberMe: true, jsonOnly: true
			}

			ajaxSend({
				url: "/SignUp/authorizationpassword",
				data: params
			}).then(data => {
				if (data.result) {
					setCookie("tum-chat-openChatOnLoad", "1");
					location.reload();
				} else {
					$(".tum-chat-unauthModal-error").html(data.msg || "Неверный пароль");
				}
			});
		});

		addEventDelegate(document, 'click', ".tum-chat-toUserExists", () => {
			this.unauthModalGoTo("userExists");
		});

		addEventDelegate(document, 'click', ".tum-chat-unauthModalJoinChat", () => {
			this.checkUnauthData();
		});

		addEventDelegate(document, 'click', ".tum-chat-backFromVerification", element => {
			var toEnterRealData = $(element).attr("data-fromEnterRealData") == "1";

			clearInterval(this.interval);
			this.timer = 0;

			if (toEnterRealData) {
				this.unauthModalGoTo("enterRealData");
			}
			else {
				$("#ChatUnauthModal").modal("hide");
			}
		});

		addEventDelegate(document, 'click', ".tum-chat-filter", () => {
			this.firmFilter = $(".tum-chat-firmFilter").val() || 0;
			this.productFilter = $(".tum-chat-productFilter").val() || 0;
			this.orderFilter = $(".tum-chat-orderFilter").val() || "";
			this.userFilter = $(".tum-chat-userFilter").val() || 0;
			this.dateFilter = $(".tum-chat-dateRange").val() || "";
			this.unreadFilter = $(".tum-chat-unreadFilter").prop("checked") || false;
			this.getRooms();
		});

		addEventDelegate(document, 'click', ".tum-chat-resetFilter", () => {
			$(".tum-chat-firmFilter").val("0");
			$(".tum-chat-productFilter").val("0");
			$(".tum-chat-userFilter").val("0");
			$(".tum-chat-orderFilter").val("");
			$(".tum-chat-dateRange").val("");
			$(".tum-chat-unreadFilter").prop("checked", false);
			$(".tum-chat-filter")[0]?.click();
		});

		$(document).on('mouseover', '.daterange', function() {
			import("./global/daterange").then(({ dateRange }) => {
				dateRange(this);
			})
		});

		addEventDelegate(document, 'click', ".tum-chat-pagination a", element => {
			if ($(element).hasClass("prev")) {
				this.options.page -= 1;
			}
			else if ($(element).hasClass("next")) {
				this.options.page += 1;
			}
			else {
				this.options.page = Number($(this).text());
			}

			this.getRooms();
		});

		addEventDelegate(document, 'click', ".tum-chat-loadOldMessages", () => {
			this.loadOldMessages();
		});

		$(document).on('hidden.bs.modal', "#ChatUnauthModal", () => {
			if (this.registrationSuccess) {

				var params = {
					login: this.anonEmail || this.anonPhone, passwordIn: this.newPassword, redirectUrl: location.href, rememberMe: true, jsonOnly: true
				}

				ajaxSend({ url: "/SignUp/authorizationpassword", data: params }).then(data => {
					if (data.result) {
						setCookie("tum-chat-openChatOnLoad", "1");
						location.reload();
					} else {
						showDialog({
							title: "Ошибка",
							content: "Ошибка авторизации нового пользователя"
						});
						$("#asModal .btn-primary").addClass("d-none");
					}
				});
			}
        });

        addEventDelegate(document, 'input', ".tum-chat-newMessageText", element => {
            this.currentText[Number($(".tum-chat-room").attr("data-id"))] = $(element).val();
        });

		addEventDelegate(document, "click", ".tum-chat-pageLink", (element, e) => {
			e.stopPropagation();
		});

		addEventDelegate(document, 'click', ".tum-chat-readByShowAll", () => {
			this.readByShowAll();
		});

		addEventDelegate(document, 'click', '.tum-chat-showPassword', () => {
			var $password = $('.tum-chat-userPassword');
			var type = $password.attr('type');

			$password.attr('type', type == 'password' ? 'text' : 'password');
		});
	},
	initChat: function (callback) {
		var role = this.options.role;

		if (role == "client") {
			if (this.options.isCabinet) {
				this.getRooms(callback);
			}
			else {
				this.getRoom({ createRoom: false, callback: callback });
			}
		}
		else if (role == "agent") {
			this.getRooms(callback);
		}

		if (this.options.callback) {
			this.options.callback();
		}
	},
	focusChat: function () {
		if ($(this.options.container).hasClass("collapse")) {
			$(this.options.container).collapse("show");
		}

		$(this.options.container).css("transition", "0.5s");

		this.loadChat(() => this.highlightChat());
	},
	highlightChat: function(){
		var offset = $(this.options.container).offset();
		offset.top -= 100;
		$('html, body').animate({
			scrollTop: offset.top
		});

		tumGlobal.highlight($(this.options.container), 2500);
	},
	messageReceived: function (data) {
        data = JSON.parse(data);
        data.item.user = data.user;
		$(".tum-chat-messages").append(this.renderMessage(data.item));
		this.scrollToBottom($(".tum-chat-messages"));

        if (this.options.username != data.user.username) {
            this.markAsRead($(".tum-chat-room").attr("data-id"));
        }
    },
	updateReadBy: function(userIDs) {
		let self = this;
		var $cont = $(".tum-chat-isReadBy");
		if (!userIDs) userIDs = $cont.attr("data-userids");
		else {
			$cont.attr("data-userids", userIDs);
		}

		var newUserIDs = userIDs ? userIDs.split(',').filter(e => e) : [];
		var oldUserIDs = $(".tum-chat-readByUser").toArray().map(e => $(e).attr("data-userid"));

		var toAdd = newUserIDs.filter(e => oldUserIDs.indexOf(e) == -1);
		var toRemove = oldUserIDs.filter(e => newUserIDs.indexOf(e) == -1);

		toRemove.forEach(id => $(".tum-chat-readByUser[data-userid='" + id + "']").remove());
		toAdd.forEach(function(id){
			if ($(".tum-chat-readByUser").length >= 3) return;
			var user = self.users.filter(e => e.userID == id);
			if (!user.length) return;
			user = user[0];

			var html = getPrecompiledTemplate('chat-read-by-user')(user);
			$cont.append(html);
		});

		if (newUserIDs.length > 3 && !$(".tum-chat-readByShowAll").length) {
			$cont.append($('<div class="tum-chat-readByShowAll mx-1" title="Показать всех"><i class="fas fa-ellipsis-h"></i></div>'));
		}
		else {
			$(".tum-chat-readByShowAll").remove();
		}
	},
	readByShowAll: function(){
		var $cont = $(".tum-chat-isReadBy");
		var userIDs = ($cont.attr("data-userids") || "").split(',').filter(e => e);
		if (!userIDs.length) return;

		var users = this.users.filter(e => userIDs.indexOf(e.userID.toString()) > -1);
		var html = getPrecompiledTemplate('chat-read-by-users-all')({ users: users });
		showDialog({ title: "Прочитано пользователями", content: html });
		$("#asModal .btn-primary").addClass("d-none");
	},
	joinRoom: function (roomID) {
		return this.hub?.invoke('JoinChatRoom', Number(roomID));
	},
	leaveRoom: function (roomID) {
		return this.hub.invoke('LeaveChatRoom', Number(roomID));
	},
	startVerification: function () {
		this.sendVerificationCode();
		this.unauthModalGoTo("verification");
		$("#ChatUnauthModal").modal({
			show: true,
			backdrop: 'static'
		});
	},
	getRoom: function (options) { //roomID, createRoom
		let self = this;
		options = options || {};
		if (!this.options.canAccessChat) {
			$(this.options.container).html(this.options.chatDisabledMessage);
			return;
		}

		if (options.createRoom === undefined) options.createRoom = true;

		$(this.options.container).addClass("tum-chat-running");

		this.messagesLoaded = 0;
		this.options.messagesPage = 1;
		this.options.messagesPaginationLastID = 0;

		var parameters = {
			firmID: this.options.firmID,
			productID: this.options.productID,
			orderID: this.options.orderID,
			pageTypeCode: this.options.role,
			isSellerChat: this.options.isSellerChat,
			isAdminChat: this.options.isAdminChat,
			roomID: options.roomID || 0,
			page: this.options.messagesPage,
			pageSize: this.options.messagesPageSize,
			paginationLastMessageID: this.options.messagesPaginationLastID,
			createRoom: options.createRoom
		};

		ajaxSend({ url: this.options.url.GetRoom, data: parameters }).then(data => {
			if (data.result) {
				self.joinRoom(data.id);
				self.users = data.users;
				self.totalMessages = data.totalMessages;
				if (data.messages.length) {
					self.options.messagesPaginationLastID = data.messages[data.messages.length - 1].id;
					self.messagesLoaded += data.messages.length;
				}
				$(self.options.container).html(self.renderRoom(data));
				self.canEditUsers = data.canEditUsers;
				self.scrollToBottom($(".tum-chat-messages"));
				if (data.totalMessages > data.messages.length) {
					$(".tum-chat-loadOldMessages").removeClass("d-none");
				}

				if (self.options.scrollDownOnLoad) {
					$("html, body").animate({ scrollTop: $(document).height() }, 1000);
                }

                $(".tum-chat-newMessageText").val(self.currentText[Number(data.id)]);

                //вставляем разделитель перед первым непрочитанным сообщением, если оно не первое в списке
				$('<div class="tum-chat-unreadMessagesSeparator mb-2"><hr><div><span class="px-2">Непрочитанные сообщения</span></div></div>').insertBefore($(".tum-chat-message[data-isread=0]:first:not(:first-child)"));
				$('.chat-user-hint').popover({
					sanitize: false
				});

				self.updateReadBy();

				if (options.callback) options.callback(data.id);
			}
			else {
				$(self.options.container).html(data.msg || "Не удалось подключиться к чату");
			}
		});
	},
	loadOldMessages: function () {
		let self = this;
        this.options.messagesPage += 1;
 
        var params = {
            roomID: $(".tum-chat-room").attr("data-id"),
            firmID: 0,
            productID: 0,
            orderID: 0,
            page: this.options.messagesPage,
            pageSize: this.options.messagesPageSize,
			paginationLastMessageID: this.options.messagesPaginationLastID,
			pageTypeCode: this.options.role
        };
 
		ajaxSend({ url: this.options.url.GetRoom, data: params }).then(data => {
			console.log(data);
            if (data.result) {
                if (data.messages.length) {
 
                    for (var i = 0; i < data.messages.length; i++) {
                        data.messages[i].user = data.users.filter(e => e.userID == data.messages[i].from); //.find
                        if (data.messages[i].user.length) data.messages[i].user = data.messages[i].user[0];
					}
					
                    $(data.messages.map(e => self.renderMessage(e)).join("")).insertAfter($(".tum-chat-loadOldMessagesCont"));
                    self.messagesLoaded += data.messages.length;
                }
                if (self.messagesLoaded == self.totalMessages) {
                    $(".tum-chat-loadOldMessages").addClass("d-none");
                }
            }
            else {
                $(".tum-chat-messages").append("<div class='tum-dark-red text-center'>Ошибка при загрузке старых сообщений</div>");
                self.scrollToBottom($(".tum-chat-messages"));
            }
        });
    },
	getRooms: function (callback) {
		$(this.options.container).addClass("tum-chat-running");

		var dateFrom = "";
		var dateTo = "";
		var daterange = this.dateFilter || $(".tum-chat-dateRange").val() || "";
		var split = daterange.split(" - ");
		if (split.length == 2) {
			dateFrom = split[0];
			dateTo = split[1];
		}

		var params = {
			firmID: this.options.firmID || this.firmFilter || $(".tum-chat-firmFilter").val() || 0,
			productID: this.options.productID || this.productFilter || $(".tum-chat-productFilter").val() || 0,
			orderID: this.options.orderID || this.orderFilter || $(".tum-chat-orderFilter").val() || 0,
			isCabinet: this.options.isCabinet,
			userFilter: this.userFilter || $(".tum-chat-userFilter").val() || 0,
			dateFrom: dateFrom,
			dateTo: dateTo,
			page: this.options.page,
			pageSize: $('.tum-result-container select').val() || this.options.pageSize,
			unread: this.unreadFilter
		}

		ajaxSend({ url: this.options.url.GetRooms, data: params }).then(data => {
			this.total = data.total;
			data.pagination = {
				total: this.total,
				totalPages: Math.ceil(this.total / (+this.pageSizeFilter || this.options.pageSize)),
				page: this.options.page,
				pages: this.getPaginationPages(this.options.page, (+this.pageSizeFilter || this.options.pageSize), this.total)
			}

			$(this.options.container).html(this.renderRooms(data));

			$(".tum-chat-firmFilter").val(this.firmFilter);
			$(".tum-chat-productFilter").val(this.productFilter);
			$(".tum-chat-orderFilter").val(this.orderFilter);
			$(".tum-chat-userFilter").val(this.userFilter);
			$(".tum-chat-dateRange").val(this.dateFilter);
			$(".tum-chat-unreadFilter").prop("checked", this.unreadFilter);
			$('.tum-result-container select').val(this.pageSizeFilter || this.options.pageSize);

			if (this.options.isCabinet) {
				import("select2").then(() => {
					return import("select2/dist/js/i18n/ru.js")
				}).then(() => {
					$('.tum-chat-firmFilter').select2({
						'minimumInputLength': 0,
						'language': "ru",
						dropdownAutoWidth: true
					});
	
					$('.tum-chat-productFilter').select2({
						'minimumInputLength': 0,
						'language': "ru",
						dropdownAutoWidth: true
					});
	
					$('.tum-chat-userFilter').select2({
						'minimumInputLength': 0,
						'language': "ru",
						dropdownAutoWidth: true
					});
				});
			}

			if (callback) callback();
		});
	},
	getRoomAnonymous: function (name, emailPhone) {
		var parseResult = this.parseEmailPhone(emailPhone);

		if (!parseResult.correct) {
			$(".tum-chat-unauthError").html("Некорректные email или телефон");
			return;
		}

		this.anonName = name;
		this.anonEmail = parseResult.email;
		this.anonPhone = parseResult.phone;

		this.showUnauthModal();
	},
	parseEmailPhone: function (emailPhone) {
		var emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		var phoneRegex = /[0-9+]+/;

		var email = "";
		var phone = "";

		var correct = true;

		if (emailregex.test(emailPhone)) {
			email = emailPhone;
		}
		else {
			phone = emailPhone;
			if (!phoneRegex.test(phone) || !((phone.startsWith("+") && phone.length == 12) || (!phone.startsWith("+") && phone.length == 11))) {
				correct = false;
			}
		}

		return {
			email: email,
			phone: phone,
			correct: correct
		}
	},
	sendMessage: function (text, roomID) {
		let self = this;
		if (!text && !this.newMessageAttachments.length) {
			return;
		}
		$(".tum-chat-sendMessage").prop("disabled", true);

        ajaxSend({ url: this.options.url.SendMessage, data: { text: text, roomID: roomID, attachments: this.newMessageAttachments.join(";"), username: this.anonEmail || this.anonPhone || "", isPhone: this.anonPhone } }).then(data => {
			if (data.result) {
                $(".tum-chat-newMessageText").val("");
                self.currentText[Number(roomID)] = "";
				self.newMessageAttachments = [];
				self.updateNewMessageAttachments();
			}
			else {
				$(".tum-chat-messages").append("<div class='tum-dark-red text-center'>Ошибка отправки сообщения</div>");
				self.scrollToBottom($(".tum-chat-messages"));
			}
			$(".tum-chat-sendMessage").prop("disabled", false);
		});
	},
	showAddUserDialog: function (roomID) {
		let self = this;
		ajaxSend({ url: this.options.url.GetRoomAvailableUsers, data: { roomID: roomID, pageTypeCode: this.options.role } }).then(data => {
			if (data.result) {
				showDialog({ title: "Добавить сотрудника к чату", content: self.renderAddUserDialog(data, roomID) });
				$("#asModal .modal-footer .btn-primary").addClass("d-none");
			}
			else {
				$(".tum-chat-messages").append("<div class='tum-dark-red text-center'>Ошибка при получении списка доступных пользователей</div>");
				self.scrollToBottom($(".tum-chat-messages"));
			}
		});
	},
    addUserToRoom: function (username, roomID, userType) {
		let self = this;
        ajaxSend({ url: this.options.url.AddUserToRoom, data: { targetUsername: username, roomID: roomID, targetUserType: userType } }).then(data => {
            if (data.result) {
                self.users.push(data.user)
				$(".tum-chat-userList").append(`<li class="tum-chat-userListItem" data-username="${data.user.username}">${data.user.name} (${data.user.userID}) <a href="javascript:void(0)" class="tum-chat-removeUser font-weight-bold" data-roomid="${roomID}">Удалить</a></li>`);
			}
			else {
				$(".tum-chat-messages").append("<div class='tum-dark-red text-center'>Ошибка добавления пользователя</div>");
				self.scrollToBottom($(".tum-chat-messages"));
			}
		});
	},
	removeUserFromRoom: function (username, roomID) {
		let self = this;
		if (!confirm("Вы уверены, что хотите удалить пользователя из чата?")) {
			return;
		}
		ajaxSend({ url: self.options.url.RemoveUserFromRoom, data: { targetUsername: username, roomID: roomID } }).then(data => {
			if (data.result) {
                $(".tum-chat-userListItem[data-username='" + username + "']").remove();
                self.users = self.users.filter(e => e.username != username);
			}
			else {
				$(".tum-chat-messages").append("<div class='tum-dark-red text-center'>Ошибка удаления пользователя</div>");
				self.scrollToBottom($(".tum-chat-messages"));
			}
		});
	},
	sendVerificationCode: function () {
		let self = this;
		ajaxSend({ url: self.options.url.SendVerificationCode, data: { email: self.anonEmail, phone: self.anonPhone } }).then(data => {
			if (data.result) {
				$(".tum-chat-verificationMsg").html(data.msg || `На ваш ${self.anonEmail ? "email" : "телефон"} <b>${self.anonEmail || self.anonPhone}</b> отправлено сообщение с 6-значным кодом, пожалуйста укажите его, чтобы продолжить общение`);
				self.timer = 30;
				$(".tum-chat-sendCode").prop("disabled", true);
				self.interval = setInterval(function () {
					if (self.timer == 0) {
						clearTimeout(self.interval);
						return;
					}
					self.timer = self.timer - 1;
					self.updateCodeButtonTimer();
				}, 1000);
			}
			else {
				$(".tum-chat-verificationMsg").html(data.msg);
			}
		});
	},
	updateCodeButtonTimer: function () {
		if (this.timer) {
			$(".tum-chat-sendCodeTimer").removeClass("d-none");
			$(".tum-chat-sendCodeTimer").html(`Доступно через ${this.timer} сек.`);
		}
		else {
			$(".tum-chat-sendCodeTimer").html("");
			$(".tum-chat-sendCodeTimer").addClass("d-none");
			$(".tum-chat-sendCode").prop("disabled", false);
		}
	},
	verifyUser: function () {
		let self = this;
		ajaxSend({ url: self.options.url.VerifyUser, data: { email: self.anonEmail, phone: self.anonPhone, name: self.anonName, code: $(".tum-chat-verificationCode").val() } }).then(data => {
			if (data.result) {
				self.registrationSuccess = true;
				self.newPassword = data.password;
				$(".tum-chat-unauthModal-loginType").html(self.anonEmail ? "email" : "телефон");
				$(".tum-chat-unauthModal-login").html(self.anonEmail || self.anonPhone);
				$(".tum-chat-unauthModal-newPassword").html(data.password);

				self.unauthModalGoTo("registration");
			}
			else {
				$(".tum-chat-verificationMsg").html(data.msg || "Неверный код подтверждения");
			}
		});
	},
	showUnauthModal: function () {
		let self = this;
		ajaxSend({ url: self.options.url.CheckUnauthData, data: { email: self.anonEmail, phone: self.anonPhone } }).then(data => {
			if (data.result) {
				if (data.username) {
					$(".tum-chat-unauthModalUsername").html(self.anonPhone || self.anonEmail);
					self.unauthModalGoTo("userExists");
					$("#ChatUnauthModal").modal({
						show: true,
						backdrop: 'static'
					});
				}
				else {
					self.startVerification();
				}
			}
			else {
				$(".tum-chat-unauthError").html("Ошибка при проверке контактов. Попробуйте еще раз после обновления страницы.");
			}
		});
	},
	checkUnauthData: function () {
		let self = this;
		var parseResult = self.parseEmailPhone($(".tum-chat-unauthModalEmailPhone").val());

		if (!parseResult.correct) {
			$(".tum-chat-unauthModal-error").html("Некорректные email или телефон");
			return;
		}

		self.anonEmail = parseResult.email;
		self.anonPhone = parseResult.phone;
		self.anonName = $(".tum-chat-unauthModalName").val() || "";

		ajaxSend({ url: self.options.url.CheckUnauthData, data: { email: self.anonEmail, phone: self.anonPhone } }).then(data => {
			if (data.result) {
				if (data.username) {
					$(".tum-chat-unauthModal-error").html("Укажите свои реальные данные");
				}
				else {
					$(".tum-chat-backFromVerification").attr("data-fromEnterRealData", "1");
					self.startVerification();
				}
			}
			else {
				$(".tum-chat-unauthModal-error").html("Ошибка при проверке контактов");
			}
		});
	},
	unauthModalGoTo: function (code) {
		$(".tum-chat-unauthModalBody > div").addClass("d-none");
		$(".tum-chat-unauthModalFooter > div").addClass("d-none");
		$(".tum-chat-unauthModal-error").html("");

		if (code == "userExists") { $(".tum-chat-unauthModalUserExists").removeClass("d-none"); }
		else if (code == "enterRealData") { $(".tum-chat-unauthModalEnterRealData").removeClass("d-none"); }
		else if (code == "login") { $(".tum-chat-unauthModalLogin").removeClass("d-none"); }
		else if (code == "verification") { $(".tum-chat-unauthModalVerification").removeClass("d-none"); }
		else if (code == "registration") { $(".tum-chat-unauthModalRegistration").removeClass("d-none"); }
	},
	updateNewMessageAttachments: function () {
		$(".tum-chat-newMessageAttachments").html(this.renderNewMessageAttachments());
	},
	scrollToBottom: function (cont) {
		if (cont.length)
			cont.scrollTop(cont.prop("scrollHeight"));
	},
	isUserOnline: function (users, name) {
		return $.inArray(name, users) !== -1;
	},
    renderRoom: function (data) {
        var urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

		for (var i = 0; i < data.messages.length; i++) {
            data.messages[i].currentUsername = this.options.username || this.anonName;
            data.messages[i].text = data.messages[i].text?.replace(urlRegex, '<a href="$&" target="_blank">$&</a>');
            data.messages[i].role = this.options.role;
            data.messages[i].user = data.users.filter(e => e.userID == data.messages[i].from); //.find
			if (data.messages[i].user.length) data.messages[i].user = data.messages[i].user[0]; 
		}

		data.role = this.options.role;

		return getPrecompiledTemplate('chat')(data);
	},
	renderRooms: function (data) {

		data.isCabinet = this.options.isCabinet;
		data.pageSize = this.options.pageSize;
		for (var i = 0; i < data.items.length; i++) {
			data.items[i].isCabinet = this.options.isCabinet;
		}

		return getPrecompiledTemplate('chat-room-list')(data);
	},
    renderMessage: function (data) {
        var urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

		data.text = data.text.replace(urlRegex, '<a href="$&" target="_blank">$&</a>')

        data.currentUsername = this.options.username || this.anonName;
        data.role = this.options.role;
		return getPrecompiledTemplate('chat-message')(data);
	},
	renderAddUserDialog: function (data, roomID) {
		data.roomID = roomID;
		data.currentUsers = this.users;
		return getPrecompiledTemplate('chat-add-user')(data);
	},
	renderNewMessageAttachments: function () {
		var data = { items: [] };
		if (this.newMessageAttachments.length) {
			data.items = this.newMessageAttachments.map(e => ({ url: e, filename: e.split("___")[e.split("___").length - 1] }));
		}
		return getPrecompiledTemplate('chat-message-attachments')(data);
	},
	renderUnauthForm: function () {
		return getPrecompiledTemplate('chat-unauthorized')({});
	},
	getPaginationPages: function (page, pageSize, total) {
		var res = [];
		var totalPages = Math.ceil(total / pageSize);
		if (page < 5) {
			res = [1, 2, 3, 4, 5];
		}
		else if (page > totalPages - 4) {
			res = [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
		}
		else {
			res = [page - 2, page - 1, page, page + 1, page + 2];
		}

		return res.filter(e => e > 0 && e <= totalPages);
	},
	destroy: function () {
		var roomID = $(".tum-chat-room").attr("data-id");

		if (!roomID) return Promise.resolve();

		var $activeCount = $(".chatMessageCount.active");
		$activeCount.attr("data-unread", 0);

		$(this.options.container).html("");
		$(".tum-chat-running").removeClass("tum-chat-running");
		$(".chatMessageCount").removeClass("active");

		return this.leaveRoom(roomID);
	},
	updateMessageCount: function (roomID, total, unread) {
		if (total < 1) {
			return;
		}

		var $count = $(".chatMessageCount[data-roomid='" + roomID + "']");

		$count.attr("data-total", total);
		$count.attr("data-unread", unread);

		if ($count.hasClass("active") || unread == 0) {
			$count.html(total);
		}
		else {
			$count.html(total + "/<span class='tum-dark-red'>" + unread + "</span>");
		}
	},
	incrementMessageCount: function (roomID) {
		var $count = $(".chatMessageCount[data-roomid='" + roomID + "']");

		var total = Number($count.attr("data-total")) + 1;
		var unread = Number($count.attr("data-unread")) + 1;

		this.updateMessageCount(roomID, total, unread);
	},
	markAsRead: function (roomID) {
		if (!roomID) return;
		ajaxSend({ url: this.options.url.MarkAsRead, data: { roomID: roomID, pageTypeCode: this.options.role }});
    }
};

export default chat;